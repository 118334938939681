@import 'react-perfect-scrollbar/dist/css/styles.css';

body {
  min-width: 320px;
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif !important;
  background-color: transparent !important;
}

.MuiFormLabel-root {
  color: rgba(0, 0, 0, 0.87) !important;
  &.Mui-disabled {
    color: rgba(0, 0, 0, 0.38) !important;
  }
}

.MuiPopover-paper.MuiPaper-rounded {
  border-radius: 15px !important;
  padding: 12.5px 19px 17px 15px;
}

.MuiDialog-container {
  margin: 0 15px;
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.MuiAutocomplete-popper {
  .MuiPaper-root {
    max-height: 280px;
    border-radius: 15px;
    & ::-webkit-scrollbar {
      width: 6px;
    }
    & ::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #fafafa;
      margin: 15px 0;
    }

    & ::-webkit-scrollbar-thumb {
      min-height: 30px;
      border-radius: 10px;
      background-color: #ccc;
    }
  }
  .MuiAutocomplete-listbox {
    padding: 16px 12px;
    max-height: 280px;
  }
  .MuiAutocomplete-option {
    border-radius: 12px;
    padding: 10px 16px;
    font-size: 14px;
    font-weight: 500;
    &:hover {
      background-color: #f3fdfc;
      color: #0dd1c5;
      border-radius: 12px;
    }
    &[aria-selected='true'] {
      background-color: #fafafa !important;
    }
    &[data-focus='true'] {
      background-color: #fafafa !important;
    }
  }
}

.country-list {
  transform: translate(-16px, -8px);
  border-radius: 15px !important;
  max-width: 284px;
}
